<template>
  <div class="relative">
    <Button class="text-center" kind="link" :label="getOrganization.title" @clicked="switchOrgSwitcher" />

    <div
      v-if="showOrgSwitcher"
      v-click-outside="switchOrgSwitcher"
      class="overflow-scroll absolute left-1/2 p-4 mt-6 bg-blue-base rounded"
      style="transform: translate(-50%, 0); max-height: 32rem"
    >
      <InputText
        placeholder="Search by org name..."
        title="Search by org name..."
        type="text"
        @onChange="catchSearchString"
      />

      <div
        v-for="(org, orgIndex) in sortedOrgs"
        :key="orgIndex"
        :class="orgIndex < sortedOrgs.length - 1 ? 'border-b-2 border-blue-light border-solid' : ''"
        :title="`${org.title} - ${org._id}`"
        class="py-2"
      >
        <Button kind="link" :label="org.title" @clicked="switchOrg(org._id)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Button } from "cavea-design-system";
import vClickOutside from "v-click-outside";
import log from "@/lib/log";

export default {
  name: "OrgSwitcher",

  components: {
    Button,
    InputText: () => import("@/components/inputs/InputText"),
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      showOrgSwitcher: false,
      searchText: "",
    };
  },

  computed: {
    ...mapGetters(["getOrganization", "getOrganizations"]),

    sortedOrgs() {
      return this.getOrganizations
        ?.filter(
          (org) =>
            org?._id !== this.getOrganization?._id &&
            org?.title?.toLowerCase()?.includes(this.searchText?.trim()?.toLowerCase())
        )
        ?.sort((a, b) => a?.title > b?.title);
    },
  },

  methods: {
    ...mapActions(["switchOrganization"]),

    switchOrgSwitcher() {
      this.showOrgSwitcher = !this.showOrgSwitcher;
    },

    /**
     * @param {string} e
     */
    catchSearchString(e) {
      this.searchText = e || "";
    },

    switchOrg(orgId) {
      log({ fn: "switchOrg", orgId });
      this.showOrgSwitcher = !this.showOrgSwitcher;

      if (orgId) {
        log(`Switching to orgid: ${orgId}`);

        this.switchOrganization(orgId);
      }
    },
  },
};
</script>
